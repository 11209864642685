import React from "react";
import { graphql } from 'gatsby';
import Service from '../../components/Services/Service';
import AbcIcon from '/src/assets/servicesIcons/abc_white.svg';
import { Text } from "../../components/Common";
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { SERVICES_ORIGINAL_BREADCRUMBS } from '../../constants';

const FullStackPage = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema("Full Stack Develop", SERVICES_ORIGINAL_BREADCRUMBS);

  return (
    <Service
      header="services page full stack header"
      width="30vw"
      seo={{
        title: 'SEO title service full stack',
        description: 'SEO description service full stack',
        breadcrumbsElementsSchema
      }}
      Icon={AbcIcon}
    >
      <>
        <Text text="Fullstack End to End Description1" variant="body1" component="p" />
        <br />
        <Text text="Fullstack End to End Description2" variant="body1" component="p" />
        <br />
        <Text text="Fullstack End to End Description3" variant="body1" component="p" />
        <br />
        <Text text="Fullstack End to End Description4" variant="body1" component="p" />
        <br />
        <Text text="Fullstack End to End Description5" variant="body1" component="p" />
      </>
    </Service>
  );
}

export default FullStackPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
